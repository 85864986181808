import React from 'react';
import IntMessageFormat from 'intl-messageformat';
import memoize from 'fast-memoize';
import type { Dictionaries } from '.';

type Cache = Map<
  string,
  {
    item: string;
    locale: string;
    params: any;
    translation: any;
  }
>;
const getIntMessageFormat = memoize((item: string, locale: string) => {
  return new IntMessageFormat(item, locale);
});
const itemTranslation = (
  item: string,
  locale: string,
  params: any,
  itemKey: string,
  cache: Cache,
  onError?: (e: string) => void,
) => {
  const cachedItem = cache.get(itemKey);
  if (
    cachedItem &&
    cachedItem.item === item &&
    cachedItem.locale === locale &&
    Object.is(cachedItem.params, params)
  ) {
    return cachedItem.translation;
  }
  try {
    const translation = getIntMessageFormat(item, locale).format<
      string | React.ReactNode
    >(params);
    const translationWithKeys = Array.isArray(translation)
      ? React.Children.toArray(translation)
      : translation;
    cache.set(itemKey, {
      item,
      locale,
      params,
      translation: translationWithKeys,
    });
    return translationWithKeys;
  } catch (error) {
    const errorMsg = `[React translate]: ${error}`;
    onError ? onError(errorMsg) : console.error(errorMsg);
    return item;
  }
};

export type TranslateOptions = {
  isDev?: boolean;
  forceShowKey?: boolean;
  onError?: (e: string) => void;
};

export function getTranslate<M>(
  dictionaries?: Dictionaries,
  locale = 'en',
  options: TranslateOptions = {
    isDev: false,
    forceShowKey: false,
  },
) {
  // cache to make sure we don't unnecessarily re-format a message when none of the translate parameters have changed
  const cache: Cache = new Map();
  function translate<
    T extends keyof M,
    S extends M[T] extends Array<any> ? M[T][number] : null,
    SR extends NonNullable<S>,
    P extends {
      [key in SR]: any;
    },
  >(
    itemKey: T,
    params?: S extends never ? undefined : Pick<P, Required<SR>>,
  ): any;
  function translate(itemKey: any, params: any) {
    const { isDev, forceShowKey, onError } = options;
    if (forceShowKey) return itemKey;
    if (!dictionaries || !itemKey) {
      onError && onError('[React translate]: Dictionary or itemKey not found');
      return isDev ? itemKey : '';
    }
    const item = dictionaries[itemKey];
    if (!item) {
      onError &&
        onError(
          `[React translate]: key: ${itemKey} not found in dictionary for locale ${locale}`,
        );
      return isDev ? itemKey : '';
    }
    return itemTranslation(item, locale, params, itemKey, cache);
  }
  return translate;
}
