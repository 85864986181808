// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @typescript-eslint/ban-types */
import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';
import { useInView } from '@vcc-www/hooks';
import { useTracker } from '@volvo-cars/tracking';

type Interaction = 'play' | 'pause' | '';
type VideoRef = React.MutableRefObject<HTMLVideoElement | null>;

export const DEFAULT_TRACK_LABEL = 'video toggle';
export const DEFAULT_GA3_TRACK_LABEL = 'video-toggle';

const useVideoEndHandler = ({
  videoRef,
  pause,
}: {
  videoRef: VideoRef;
  pause: Function;
}) => {
  useEffect(() => {
    const onVideoEnd = () => pause();
    const _videoRef = videoRef;

    videoRef?.current?.addEventListener('ended', onVideoEnd);
    return () => {
      _videoRef?.current?.removeEventListener('ended', onVideoEnd);
    };
  }, [videoRef, pause]);
};

const useVideoPausedState = ({
  videoRef,
  setIsPaused,
}: {
  videoRef: VideoRef;
  setIsPaused: React.Dispatch<boolean>;
}) => {
  useEffect(() => {
    const onVideoPause = () => {
      setIsPaused(true);
    };

    const onVideoPlay = () => {
      setIsPaused(false);
    };

    const _videoRef = videoRef;
    videoRef?.current?.addEventListener('pause', onVideoPause);
    videoRef?.current?.addEventListener('play', onVideoPlay);
    return () => {
      _videoRef?.current?.removeEventListener('pause', onVideoPause);
      _videoRef?.current?.removeEventListener('play', onVideoPlay);
    };
  }, [videoRef, setIsPaused]);
};

const useVideoSpaceHandler = ({
  setInteractedState,
  videoRef,
}: {
  setInteractedState: Dispatch<SetStateAction<Interaction>>;
  videoRef: VideoRef;
}) => {
  useEffect(() => {
    const keyUpHandler = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        setInteractedState((previousInteraction) =>
          previousInteraction === 'pause' ? 'play' : 'pause',
        );
      }
    };
    const _videoRef = videoRef;
    videoRef?.current?.addEventListener('keyup', keyUpHandler);
    return () => {
      _videoRef?.current?.removeEventListener('keyup', keyUpHandler);
    };
  }, [setInteractedState, videoRef]);
};

export const useVideoManager = ({
  shouldAutoplay,
  loop,
}: {
  shouldAutoplay: boolean;
  loop?: boolean;
}) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(!shouldAutoplay);
  /** used for tracking the actual user interaction with the video */
  const [interactedState, setInteractedState] = useState<Interaction>('');
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [inViewRef, inView] = useInView<HTMLDivElement>({
    triggerOnce: false,
    rootMargin: '0px 0px',
  });

  const play = useCallback(async () => {
    try {
      if (isMounted) {
        await videoRef?.current?.play();
        setIsPaused(false);
      }
    } catch (error) {
      // some older browsers and browsers in webviews
      // will error with trying to autoplay even if it's muted.
      // Not much you can do there, but at least we can catch
      // that and update the state accordingly. [TC]
      if (isMounted) {
        setIsPaused(videoRef.current?.paused ?? true);
      }
    }
  }, [videoRef, isMounted]);

  const pause = useCallback(() => {
    videoRef?.current?.pause();
  }, [videoRef]);

  // Performs a cleanup on unmount
  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  // handle in view changes
  useEffect(() => {
    const paused = !!videoRef.current?.paused;
    const completed =
      !loop && videoRef?.current?.currentTime === videoRef?.current?.duration;

    let playPromise = new Promise(() => {});
    /**
     * `play` is async. So when play is pressed it still sees videoRef as
     * `paused = true` potentially triggering the multiple checks.
     * Need to make sure that any deps related to this effect are updated
     * `await`ing * or `.then`ing after play
     */
    if (shouldAutoplay && interactedState !== 'pause' && !completed) {
      if (inView && paused) {
        playPromise = play();
        videoRef.current?.focus({ preventScroll: true });
      } else if (!inView && !paused) {
        playPromise.then(() => {
          pause();
        });
      }
    }
  }, [inView, interactedState, videoRef, pause, play, loop, shouldAutoplay]);

  useEffect(() => {
    if (interactedState === 'play' && videoRef.current) {
      play();
    }
    if (interactedState === 'pause' && videoRef.current) {
      pause();
    }
  }, [interactedState, pause, play]);

  const togglePaused = useCallback(async () => {
    if (videoRef.current?.paused) {
      setInteractedState('play');
      await play();
    } else {
      setInteractedState('pause');
      pause();
    }
  }, [videoRef, pause, play]);

  useVideoEndHandler({ videoRef, pause });
  useVideoPausedState({ videoRef, setIsPaused });
  useVideoSpaceHandler({ videoRef, setInteractedState });

  // Autoplay
  useEffect(() => {
    if (shouldAutoplay && videoRef && isPaused) {
      play();
    }
  }, [videoRef, shouldAutoplay, play]); // eslint-disable-line

  return {
    videoRef,
    inViewRef,
    togglePaused,
    isPaused,
    inView,
    setInteractedState,
    interactedState,
  };
};

export const usePosterClickHandler = ({
  trackLabel = DEFAULT_TRACK_LABEL,
  actionText,
  togglePaused,
  setInteractedState,
  interactedState,
  controls,
}: {
  trackLabel: string;
  actionText: Interaction;
  togglePaused: () => void;
  setInteractedState: Dispatch<SetStateAction<Interaction>>;
  interactedState: Interaction;
  controls: string;
}) => {
  const tracker = useTracker({ eventLabel: trackLabel });

  const handlePosterClickPreInteraction = useCallback(() => {
    tracker.interaction({ eventAction: actionText });
    if (controls === 'enabled') {
      togglePaused();
    }
  }, [tracker, actionText, togglePaused, controls]);

  const handlePosterClickPostInteraction = useCallback(() => {
    if (actionText === 'play') {
      tracker.interaction({ eventAction: actionText });
    }
    setInteractedState(actionText);
    if (controls === 'enabled') {
      togglePaused();
    }
  }, [setInteractedState, actionText, tracker, togglePaused, controls]);

  return interactedState
    ? handlePosterClickPostInteraction
    : handlePosterClickPreInteraction;
};
