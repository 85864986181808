/**
 * @param {string} [path] - string to add ending slash to
 * @returns {string | undefined}
 */
function addEndingSlash(path) {
  if (path && path.length > 0 && !path.endsWith('/')) {
    return `${path}/`;
  }
  return path;
}
module.exports = { addEndingSlash };
