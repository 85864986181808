import React from 'react';
import SectionContained from '@vcc-www/page-section/DEPRECATED_BROKEN_SectionContained';
import { AspectRatio } from '@vcc-www/utils/getAspectRatioCSS';
import VideoProps, {
  YoutubeVideo as YoutubeVideoProps,
  OptimizedVideo as OptimizedVideoProps,
} from './VideoProps';
import { ExtraProps } from './Video';
import OptimizedVideo from './OptimizedVideo';
import { getAspectRatio } from './Video.utils';
import VideoDeprecated from './VideoDeprecated';
import YouTubeVideo from './YouTubeVideo';

const VideoEditorial: React.FC<
  React.PropsWithChildren<VideoProps & ExtraProps>
> = ({
  content,
  onTimeUpdate,
  onPlay,
  currentTime,
  onLoadedMetadata,
  withContainer = false,
  'data-autoid': autoId,
  'data-testid': testId,
  trackLabel,
  ga3TrackLabel,
  trackEventAction,
  iconVariant,
  loading,
  background,
  ...params
}) => {
  const { videos } = content;
  if (!Array.isArray(videos) || !videos.length) {
    return <VideoDeprecated content={content} {...params} />;
  }

  const {
    loop,
    autoplay,
    largePlayIcon,
    lazyLoadPoster,
    centeredPlayIcon,
    forceLoad,
    aspectRatio,
    ...sectionParams
  } = params;

  const renderedVideos = videos.map(
    (videoContent: YoutubeVideoProps | OptimizedVideoProps, idx: number) => {
      if ('youtubeURL' in videoContent && !!videoContent.youtubeURL) {
        return (
          <YouTubeVideo
            key={idx}
            content={videoContent}
            params={params}
            onPlay={onPlay}
            trackLabel={trackLabel}
            ga3TrackLabel={ga3TrackLabel}
            trackEventAction={trackEventAction}
          />
        );
      }
      const {
        videoDesktopMp4,
        videoMobileMp4,
        videoDesktopWebM,
        videoMobileWebM,
        desktopPoster,
        mobilePoster,
        subtitleTrack,
        mobileAspectRatio,
        desktopAspectRatio,
        controls,
        videoDescription,
        ...props
      } = videoContent as OptimizedVideoProps;
      const calculatedAspectRatio: AspectRatio = getAspectRatio(
        mobileAspectRatio,
        desktopAspectRatio,
      );

      const calculatedOrPassedDownAspectRatio =
        calculatedAspectRatio.default[0] === 0
          ? aspectRatio
          : calculatedAspectRatio;

      return (
        <OptimizedVideo
          key={idx}
          poster={{
            default: mobilePoster?.src || desktopPoster?.src || '',
            fromM: desktopPoster?.src,
          }}
          srcMp4={{
            default: videoMobileMp4?.src || videoDesktopMp4?.src || '',
            fromM: videoDesktopMp4?.src,
          }}
          srcWebM={{
            default: videoMobileWebM?.src || videoDesktopWebM?.src || '',
            fromM: videoDesktopWebM?.src,
          }}
          videoDescription={videoDescription}
          aspectRatio={calculatedOrPassedDownAspectRatio}
          loop={loop}
          controls={controls}
          trackLabel={trackLabel}
          ga3TrackLabel={ga3TrackLabel}
          largePlayIcon={largePlayIcon}
          lazyLoadPoster={lazyLoadPoster}
          centeredPlayIcon={centeredPlayIcon}
          subtitleTrack={subtitleTrack?.src}
          onTimeUpdate={onTimeUpdate}
          onPlay={onPlay}
          currentTime={currentTime}
          onLoadedMetadata={onLoadedMetadata}
          data-autoid={autoId ? autoId : 'video:video'}
          data-testid={testId ? testId : 'video:optimized'}
          iconVariant={iconVariant}
          loading={loading}
          {...props}
          // CMS keeps old fields, so old templates have `shouldAutplay` as false
          // meaning spread after overrides the param [TC]
          shouldAutoplay={autoplay}
          forceLoad={forceLoad}
          background={background}
          offsetYVideoControl={params.offsetYVideoControl}
        />
      );
    },
  );

  // withContainer makes sure that rendered videos are wrapped in
  // SectionContainer. This is needed when Video editorial component is used
  // from another editorial component that already has SectionContainer wrapper.
  if (withContainer) {
    return (
      <SectionContained {...sectionParams} data-testid="video:contained">
        {renderedVideos}
      </SectionContained>
    );
  }

  return <>{renderedVideos}</>;
};

export default VideoEditorial;
